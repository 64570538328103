import { Badge, Button, Col, Container, Nav, Row, Tab } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import Loading from 'components/Loading';
import { loadMultiple as loadEvents } from 'data/actions/events';
import { load as loadUser } from 'data/actions/users';
import {
  getCurrentUser,
  getCurrentUserActiveEvents,
  getCurrentUserArchivedEvents,
  isLoadingEvents,
} from 'data/reducers';
import Search from 'images/icons/search.png';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import DashboardEventsGrid from './components/DashboardEventsGrid';
import Styles from './Dashboard.module.scss';

const Dashboard = ({ loadEvents, user, loadUser, loading, archivedEvents, activeEvents }) => {
  const [key, setKey] = useState('activeCohorts');
  const [eventFilter, setEventFilter] = useState('');

  const [orderBy, setOrderBy] = useState('');
  const [sortOrder, setSortOrder] = useState('');

  useEffect(() => {
    // Reload user events
    loadUser(user.get('id'));
    loadEvents();
  }, []);

  const search = (name) => {
    setEventFilter(name.toLowerCase());
  };

  const filteredEventsActive = activeEvents
    .filter((item) => item.get('name').toLowerCase().includes(eventFilter))
    .sort(function (a, b) {
      if (orderBy === 'name') {
        const nameA = a.get('name').toUpperCase();
        const nameB = b.get('name').toUpperCase();
        if (nameA < nameB) {
          return sortOrder === 'asc' ? -1 : 1;
        }
        if (nameA > nameB) {
          return sortOrder === 'asc' ? 1 : -1;
        }
        return 0;
      } else {
        const dateA = new Date(a.get('created_at'));
        const dateB = new Date(b.get('created_at'));
        if (dateA < dateB) {
          return sortOrder === 'asc' ? -1 : 1;
        }
        if (dateA > dateB) {
          return sortOrder === 'asc' ? 1 : -1;
        }
        return 0;
      }
    });

  const filteredEventsArchived = archivedEvents.filter((item) =>
    item.get('name').toLowerCase().includes(eventFilter),
  );

  const orderByHandler = (order) => {
    setOrderBy(order);
    if (sortOrder === '' || sortOrder === 'desc' || orderBy !== order) {
      setSortOrder('asc');
    } else {
      setSortOrder('desc');
    }
  };

  return (
    <Container>
      <Tab.Container activeKey={key}>
        <Row className={cx('mt-3 ms-0 me-0', Styles.SectionWrapper)}>
          <Col xs={12}>
            <h1 className={Styles.titleDashboard}>Dashboard</h1>
          </Col>
        </Row>
        <Row className={cx('ms-0 me-0', Styles.SectionWrapper)}>
          <Col md={7}>
            <Nav activeKey={key} onSelect={(k) => setKey(k)}>
              <Nav.Item>
                <Nav.Link
                  eventKey="activeCohorts"
                  className={cx('pe-3 ps-3', Styles.tabLeft, {
                    btn: true,
                    'btn-primary': key === 'activeCohorts',
                    'btn-default tab-border': key !== 'activeCohorts',
                  })}
                >
                  Active Cohorts
                  <Badge
                    bg="light"
                    className={cx({
                      [Styles.badgeActive]: key === 'activeCohorts',
                      [Styles.badgeInactive]: key !== 'activeCohorts',
                    })}
                  >
                    {filteredEventsActive.size}
                  </Badge>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="archivedCohorts"
                  className={cx('pe-3 ps-3', Styles.tabRight, {
                    btn: true,
                    'btn-primary': key === 'archivedCohorts',
                    'btn-default tab-border': key !== 'archivedCohorts',
                  })}
                >
                  Archived Cohorts
                  <Badge
                    bg="light"
                    className={cx({
                      [Styles.badgeActive]: key === 'archivedCohorts',
                      [Styles.badgeInactive]: key !== 'archivedCohorts',
                    })}
                  >
                    {filteredEventsArchived.size}
                  </Badge>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col md={5} className="d-flex align-items-center justify-content-md-end">
            <Button
              className={Styles.btnFilter}
              variant={'link'}
              onClick={() => orderByHandler('name')}
            >
              <span
                className={cx('fa-stack fa-2x', {
                  [Styles.activeSorting]: orderBy === 'name',
                })}
              >
                <i
                  className={cx('fa-solid fa-stack-2x', {
                    'fa-arrow-down-a-z':
                      (sortOrder === 'asc' && orderBy === 'name') || orderBy !== 'name',
                    'fa-arrow-up-a-z': sortOrder === 'desc' && orderBy === 'name',
                  })}
                ></i>
              </span>
            </Button>
            <Button
              className={Styles.btnFilter}
              variant={'link'}
              onClick={() => orderByHandler('date')}
            >
              <span
                className={cx('fa-stack fa-2x', {
                  [Styles.activeSorting]: orderBy === 'date',
                })}
              >
                <i
                  className={cx('fa-solid  fa-stack-2x', {
                    'fa-calendar-arrow-down':
                      (sortOrder === 'asc' && orderBy === 'date') || orderBy !== 'date',
                    'fa-calendar-arrow-up': sortOrder === 'desc' && orderBy === 'date',
                  })}
                ></i>
              </span>
            </Button>

            <div className="position-relative">
              <input
                autoFocus
                className={cx('form-control', Styles.SearchInput)}
                placeholder={'Search by Cohort Name'}
                onChange={(e) => search(e.target.value)}
              />
              <img className={Styles.SearchInputIcon} src={Search} alt={'Search by Cohort Name'} />
            </div>
          </Col>
        </Row>
        <Row className={cx('ms-0 me-0', Styles.SectionWrapper)}>
          <Col xs={12}>
            {loading ? (
              <Loading />
            ) : (
              <Tab.Content>
                <Tab.Pane eventKey="activeCohorts">
                  {filteredEventsActive?.size > 0 && (
                    <DashboardEventsGrid user={user} events={filteredEventsActive} />
                  )}
                  {filteredEventsActive?.size <= 0 && 'Not found'}
                </Tab.Pane>
                <Tab.Pane eventKey="archivedCohorts">
                  {filteredEventsArchived?.size > 0 && (
                    <DashboardEventsGrid user={user} events={filteredEventsArchived} />
                  )}
                  {filteredEventsArchived?.size <= 0 && 'Not found'}
                </Tab.Pane>
              </Tab.Content>
            )}
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  );
};

Dashboard.displayName = 'Dashboard';

export default connect(
  (state) => {
    const user = getCurrentUser(state);
    return {
      user,
      activeEvents: getCurrentUserActiveEvents(state),
      archivedEvents: getCurrentUserArchivedEvents(state),
      loading: isLoadingEvents(state),
    };
  },
  { loadEvents, loadUser },
)(Dashboard);
